import * as THREE from "three";

export function isFront(
	camera: THREE.PerspectiveCamera,
	mesh: THREE.Group<THREE.Object3DEventMap>
): boolean {
	const cameraDirection = new THREE.Vector3();
	camera.getWorldDirection(cameraDirection);

	const textDirection = new THREE.Vector3();
	mesh.getWorldDirection(textDirection);

	// Calculate the dot product of the camera and text direction vectors
	const dot = cameraDirection.dot(textDirection);

	return dot > 0 ? false : true;
}
