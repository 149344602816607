import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { Font, FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import GUI from "lil-gui";
import { isFront } from "./animate";

/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load("textures/matcaps/8.png");
matcapTexture.colorSpace = THREE.SRGBColorSpace;

/* 
    Raycaster
*/
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

function onPointerMove(event) {
	// calculate pointer position in normalized device coordinates
	// (-1 to +1) for both components

	pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
	pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
}

/**
 * Fonts
 */
const fontLoader = new FontLoader();

const engTextLoader = (font: Font, material: THREE.MeshMatcapMaterial) => {
	// Text
	const engMainTextGeometry = new TextGeometry(
		`Hello, \nmy name \nis Fabrizio \nand I'm \nsearching \nfor an\napprentiship`,
		{
			font: font,
			size: 0.5,
			depth: 0.2,
			curveSegments: 12,
			bevelEnabled: true,
			bevelThickness: 0.03,
			bevelSize: 0.02,
			bevelOffset: 0,
			bevelSegments: 5,
		}
	);
	engMainTextGeometry.center();
	engMainTextGeometry.translate(0, 1, 0);

	const engContactTextGeometry = new TextGeometry(`contact me`, {
		font: font,
		size: 0.5,
		depth: 0.2,
		curveSegments: 12,
		bevelEnabled: true,
		bevelThickness: 0.03,
		bevelSize: 0.02,
		bevelOffset: 0,
		bevelSegments: 5,
	});
	engContactTextGeometry.center();
	engContactTextGeometry.translate(0, -2.5, 0);

	const engMainTextMesh = new THREE.Mesh(engMainTextGeometry, material);
	engMainTextMesh.name = "en_main";
	const engContactTextMesh = new THREE.Mesh(engContactTextGeometry, material);
	engContactTextMesh.name = "contact";

	const meshGroupEng = new THREE.Group();

	return meshGroupEng.add(engMainTextMesh, engContactTextMesh);
};

const frTextLoader = (font: Font, material: THREE.MeshMatcapMaterial) => {
	// Text
	const frMainTextGeometry = new TextGeometry(
		`Salut, \nje m'appelle\nFabrizio \net je cherche\nune\nalternance`,
		{
			font: font,
			size: 0.5,
			depth: 0.2,
			curveSegments: 12,
			bevelEnabled: true,
			bevelThickness: 0.03,
			bevelSize: 0.02,
			bevelOffset: 0,
			bevelSegments: 5,
		}
	);
	frMainTextGeometry.center();
	frMainTextGeometry.translate(0, 1, 0);

	const frContactTextGeometry = new TextGeometry(`contact me`, {
		font: font,
		size: 0.5,
		depth: 0.2,
		curveSegments: 12,
		bevelEnabled: true,
		bevelThickness: 0.03,
		bevelSize: 0.02,
		bevelOffset: 0,
		bevelSegments: 5,
	});
	frContactTextGeometry.center();
	frContactTextGeometry.translate(0, -2.5, 0);

	const frMainTextMesh = new THREE.Mesh(frMainTextGeometry, material);
	frMainTextMesh.name = "fr_main";
	const frContactTextMesh = new THREE.Mesh(frContactTextGeometry, material);
	frContactTextMesh.name = "contact";

	const meshGroupFr = new THREE.Group();

	meshGroupFr.rotateY(Math.PI);

	return meshGroupFr.add(frMainTextMesh, frContactTextMesh);
};

fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font) => {
	// Material
	const material = new THREE.MeshMatcapMaterial({
		matcap: matcapTexture,
		side: THREE.FrontSide,
	});

	const meshGroupEng = engTextLoader(font, material);
	const meshGroupFr = frTextLoader(font, material);

	scene.add(meshGroupEng, meshGroupFr);

	// Donuts
	const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64);

	// for (let i = 0; i < 100; i++) {
	// 	const donut = new THREE.Mesh(donutGeometry, material);
	// 	donut.position.x = (Math.random() - 0.5) * 10;
	// 	donut.position.y = (Math.random() - 0.5) * 10;
	// 	donut.position.z = (Math.random() - 0.5) * 10;
	// 	donut.rotation.x = Math.random() * Math.PI;
	// 	donut.rotation.y = Math.random() * Math.PI;
	// 	const scale = Math.random();
	// 	donut.scale.set(scale, scale, scale);

	// 	scene.add(donut);
	// }

	// camera.lookAt(meshGroupEng.position);

	/**
	 * Animate
	 */
	const animate = () => {
		meshGroupEng.visible = isFront(camera, meshGroupEng);
		meshGroupFr.visible = isFront(camera, meshGroupFr);

		// update the picking ray with the camera and pointer position
		raycaster.setFromCamera(pointer, camera);

		// calculate objects intersecting the picking ray
		let intersects = raycaster.intersectObjects(scene.children);

		for (let i = 0; i < intersects.length; i++) {
			console.log(intersects[i].object.name);
			if (intersects[i].object.name === "contact")
				window.open("mailto:fabrizio.manetti@yahoo.it");
		}

		intersects = [];

		pointer.x = -1;
		pointer.y = -1;

		// Update controls
		controls.update();

		window.addEventListener("click", onPointerMove);

		// Render
		renderer.render(scene, camera);

		// Call tick again on the next frame
		window.requestAnimationFrame(animate);
	};

	animate();
});

/**
 * Sizes
 */
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight,
};

window.addEventListener("resize", () => {
	// Update sizes
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	// Update camera
	camera.aspect = sizes.width / sizes.height;
	camera.updateProjectionMatrix();

	// Update renderer
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
	75,
	sizes.width / sizes.height,
	0.1,
	100
);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 5;

scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
